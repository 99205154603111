.signup-holder {
  background: linear-gradient(to right, #1e90ff 50%, #d9ebf9 50%) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.signup-content {
  width: 50%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 24px 50px 24px;
  background-color: #ffffff;
  box-shadow: 0px 8px 10px 0px #00000033;
  border-radius: 24px;
  overflow: auto;

  .signup-content-headers {
    h4 {
      text-align: center;
      margin-bottom: 0;
    }
    .signup-img {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-top: 0;
      margin-bottom: 32px;
    }
  }

  .signup-content-form {
    label {
      font-size: 14px;
    }
    input {
      background-color: #ffffff !important;
    }
    p {
      text-align: center;
      font-size: 12px;
      padding-top: 0;
      margin-top: 0;
    }
  }
}
.signup-image {
  position: absolute;
  top: 0;
  left: 0;
  img {
    width: 100%;
    height: 100%;
  }
}
.affilate-style ::placeholder {
  color: #ff9800 !important;
  font-family: monospace;
}

@media screen and (max-width: 425px) {
  .signup-content {
    height: 100vh;
    width: 85%;
    overflow: auto;

    .signup-content-headers {
      h4 {
        font-size: 18px;
      }
      .signup-img {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 0;
        margin-bottom: 24px;
      }
    }
  }
  .signup-image {
    display: none;
  }
}
